<template>
  <div>
    <h1>Page Not Found</h1>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
  text-align: center;
  min-height: 100vh;
  color: black;
  align-items: center;
  background: linear-gradient(to bottom, #7ba9e0, #d2deec, #115292);
}
</style>
