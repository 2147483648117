<template>
  <div class="home">
<home-welcome-vue/>
  </div>
</template>

<script>
import HomeWelcomeVue from '@/components/HomeWelcome.vue';
export default {
  name: "HomeView",
  components: {HomeWelcomeVue},
  data() {
    return {
    
    };
  },
};
</script>
<style scoped>

</style>
