<template>
<about-section-vue/>
</template>

<script>
import AboutSectionVue from '@/components/AboutSection.vue'
export default {
components:{AboutSectionVue}
}
</script>

<style scoped>
 
</style>