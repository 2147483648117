import { render, staticRenderFns } from "./VolumeTestView.vue?vue&type=template&id=6cf751bc&scoped=true&"
import script from "./VolumeTestView.vue?vue&type=script&lang=js&"
export * from "./VolumeTestView.vue?vue&type=script&lang=js&"
import style0 from "./VolumeTestView.vue?vue&type=style&index=0&id=6cf751bc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf751bc",
  null
  
)

export default component.exports