import { render, staticRenderFns } from "./TestView.vue?vue&type=template&id=7d59bc72&scoped=true&"
import script from "./TestView.vue?vue&type=script&lang=js&"
export * from "./TestView.vue?vue&type=script&lang=js&"
import style0 from "./TestView.vue?vue&type=style&index=0&id=7d59bc72&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d59bc72",
  null
  
)

export default component.exports